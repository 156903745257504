<template>
  <div class="accessories">
    <b-table
      :data="allAudits"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="user"
        label="Usuário"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.user.name }}</b-table-column
      >

      <b-table-column
        field="event"
        label="Movimentação"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ getEventType(props.row.event) }}</b-table-column
      >

      <b-table-column
        field="created_at"
        label="Data da movimentação"
        :searchable="true"
        sortable
        v-slot="props"
        >{{
          $moment(props.row.created_at).format('DD/MM/YYYY HH:mm')
        }}</b-table-column
      >
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/accessories/store/service'
import Accessory from '../models/accessory'

export default {
  name: 'Accessories',
  data() {
    return {}
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('audits', ['allAudits']),
  },
  methods: {
    ...mapActions('audits', ['getAllAudits']),
    async init() {
      try {
        await this.getAllAudits()
      } catch (error) {
        console.log(error)
      }
      console.log(this.allAudits)
    },
    getEventType(event) {
      switch (event) {
        case 'create':
          return 'Criar'
        case 'update':
          return 'Atualizar'
        case 'delete':
          return 'Deletar'
        default:
          break
      }
    },
  },
}
</script>

<style scoped></style>
